<template>
  <layout :loading="loading">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h1 class="title-1">Products Manage</h1>
      <div class="d-flex align-items-center">
        <b-button
                class="mr-2"
                variant="success"
                @click="showModalForm"
        >
          <div class="d-flex align-items-center">
            <i class="bx bx-plus font-size-18 mr-2" />
            Add Product
          </div>
        </b-button>
        <b-button
                v-if="$hasAccess(['admin'])"
                variant="primary"
                class="purchases__settings-button"
                @click="openRulesPage"
        >
          <i class="bx bxs-cog" />
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-8">
                <div class="search-block d-flex">
                  <multiselect class="search-block__input mr-2" track-by="id" :multiple="true" selectLabel="" deselectLabel="" v-model="selectedCategories" :options="categories" label="title" placeholder="Select category" @input="newSearch" @search-change="newSearch"></multiselect>
                  <b-form-select v-model="departmentId" :options="departmentsList" text-field="title" value-field="id"></b-form-select>
                </div>
              </div>
            </div>

            <div class="mt-3">
              <div class="col row">
                <div class="table-responsive">
                  <b-table head-variant="light" hover :items="productList" :fields="tableFields">
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                    <template #cell(title)="data">
                      <div>
                        {{data.item.title}}<br>
                        <span class="text-secondary">{{data.item.uid}}</span>
                      </div>
                    </template>
                    <template #cell(icon)="data">
                      <img v-if="data.item.icon" class="icon-image" :src="data.item.icon" alt="">
                    </template>
                    <template #cell(platforms)="data">
                      <a v-for="platform in data.item.platforms" :key="platform.id" :href="(platform && platform.pivot ? platform.pivot.link : '')" target="_blank" class="mr-2">{{platform.title}}</a>
                    </template>
                    <template #cell(category)="data">
                      {{ data.item.category ? data.item.category.title : '' }}
                    </template>
                    <template #cell(department)="data">
                      {{ data.item.department ? data.item.department.title : '' }}
                    </template>
                    <template #cell(actions)="data">
                      <b-button
                        style="margin-top: -0.5rem;"
                        variant="outline-warning"
                        class="shadow-none border-0 p-2 mt--2"
                        @click="editHandler(data.item)"
                      >
                        <span class="fa fa-pen"></span>
                      </b-button>
                      <b-button
                        style="margin-top: -0.5rem;"
                        variant="outline-danger"
                        class="shadow-none border-0 p-2 mt--2"
                        @click="onDeleted(data.item.id)"
                      >
                        <span class="fa fa-trash"></span>
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FormModal :modalTitle="modalTitle" :buttonText="buttonText" :modalId="modalId" :modalData="modalData" @success="successHandler" />
  </layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {convertObjectToFormData} from '@/utils/converters'
import FormModal from '@/components/products/products-modal.vue'

export default {
  components: {
    FormModal
  },
  data() {
    return {
      title: 'Products',
      tableFields: [
        {
          key: 'index',
          label: '#',
          class: 'column-number',
        },
        {
          key: 'icon',
          class: 'icon-cell'
        },
        {
          key: 'title',
          sortable: true,
          class: 'v-align-middle'
        },
        {
          key: 'platforms',
          sortable: true,
        },
        {
          key: 'category',
          class: 'category-cell',
          sortable: true,
        },
        {
          key: 'department',
          sortable: true,
          class: 'department-cell'
        },
        {
          key: 'actions',
          class: 'cell-action'
        }
      ],
      searchValue: '',
      optionsEmployee: [],
      employeeSelected: [],
      tagsSelected: [],
      modalId: null,
      modalTitle: 'Create new product',
      buttonText: 'Create',
      modalData: null,
      selectedCategories: [],
      departmentId: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      products: 'products/productsList',
      categories: 'categories/getCategories',
      departments: 'departments/getDepartments',
    }),
    productList () {
      let result = [...this.products]
      if (this.selectedCategories.length) {
        result = result.filter((product) => this.selectedCategories.some(cat => +cat.id === +product.product_category_id))
      }
      if (this.departmentId) {
        result = result.filter((product) => product.department_id === this.departmentId)
      }
      return result
    },
    departmentsList () {
      return [
        { id: '', title: 'Choose a department' },
        ...this.departments
      ]
    }
  },
  async mounted() {
    this.loading = true;
    await this.newSearch()
    await this.fetchCategories()
    await this.fetchPlatforms()
    await this.fetchDepartments()
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetchProducts: 'products/fetchProducts',
      deleteProduct: 'products/deleteProduct',
      fetchCategories: 'categories/fetchCategories',
      fetchPlatforms: 'platforms/fetchPlatforms',
      fetchDepartments: 'departments/getDepartments'
    }),
    openRulesPage() {
      this.$router.push({ name: 'product-categories' });
    },
    async newSearch() {
      await this.fetchProducts()
    },
    async onDeleted (id) {
      await this.deleteProduct({
        id,
        formData: convertObjectToFormData({_method: 'DELETE'})
      })
      await this.newSearch()
    },
    showModalForm () {
      this.modalTitle = 'Create new product'
      this.buttonText = 'Create'
      this.modalId = 'modal-category-new'
      this.modalData = null
      setTimeout(() => {
        this.$bvModal.show(this.modalId)
      }, 10)
    },
    editHandler (item) {
      this.modalTitle = 'Edit product'
      this.buttonText = 'Save'
      this.modalId = `modal-category-${item.id}`
      this.modalData = item
      setTimeout(() => {
        this.$bvModal.show(this.modalId)
      }, 10)
    },
    successHandler () {
      this.newSearch()
      this.$bvModal.hide(this.modalId)
    }
  },
}
</script>

<style scoped>
.cell-action {
  width: 100px;
  text-align: right;
}
.product-platform:not(:last-child):after {
  content: ', ';
}
.icon-image {
  height: 50px;
}
.column-number {
  width: 50px;
}
.search-block__input {
  cursor: pointer;
}
.card-body {
  min-height: 400px;
}
.multiselect__content-wrapper {
  max-height: 200px !important;
}
.table th, .table td {
  vertical-align: middle;
}
.icon-cell{
  width: 80px;
}
.department-cell, .category-cell{
  width: 200px;
}
</style>
