<template>
  <b-modal
    :id="modalId"
    :title="modalTitle"
    hide-footer
    centered
    scrollable
    title-class="font-18"
    ref="modalBoard"
    @hidden="handleCloseModal"
    @shown="setData"
  >
    <b-form
      class="purchase-form"
      @submit="handleSubmit"
    >
      <b-form-group
        label="Title"
        label-cols-md="2"
        label-align="right"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="form.title"
          type="text"
          required
          @change="onTitleChange()"
        />
      </b-form-group>
      <b-form-group
        label="UID"
        label-cols-md="2"
        label-align="right"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="form.uid"
          type="text"
          placeholder="Unique ID inside company"
          required
        />
      </b-form-group>
      <b-form-group
        label="Category"
        label-cols-md="2"
        label-align="right"
        label-for="title"
      >
        <b-form-select v-model="form.categoryId" text-field="title" value-field="id" :options="categoriesList"></b-form-select>
      </b-form-group>
      <b-form-group
        label="Department"
        label-cols-md="2"
        label-align="right"
        label-for="title"
      >
        <b-form-select v-model="form.departmentId" text-field="title" value-field="id" :options="departmentsList"></b-form-select>
      </b-form-group>
      <vue-upload-multiple-image
        @upload-success="uploadImageSuccess"
        @before-remove="beforeRemove"
        @edit-image="editImage"
        :data-images="images"
        dragText="Upload image. Please, upload files less than 1Mb."
        browseText="Browse image"
        dropText="Drop image"
        :multiple="false"
        :showPrimary="false"
      ></vue-upload-multiple-image>
      <div class="row mt-3">
        <p class="col-md-2 text-right font-weight-bold">Platforms</p>
        <div class="col text-right">
          <b-button
            class="add-board-btn"
            size="sm"
            variant="primary"
            @click="addPlatforms()"
          >
            <i class="fa fa-plus"></i> Add
          </b-button>
        </div>
      </div>
      <div v-for="(platform, index) in platforms" :key="index" class="product-form__platform">
        <div class="d-flex justify-content-between mb-2">
          <span>Platform {{platforms.length - index}}</span>
          <b-button
            size="sm"
            variant="danger"
            @click="removePlatform(index)"
          >
            remove
          </b-button>
        </div>
        <b-form-group
          label="ID"
          label-cols-md="2"
          label-align="right"
          label-for="title"
        >
          <b-form-select v-model="platform.id" text-field="title" value-field="id" :options="platformsList"></b-form-select>
        </b-form-group>
        <b-form-group
          label="Link"
          label-cols-md="2"
          label-align="right"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="platform.link"
            type="text"
            required
          />
        </b-form-group>
        <b-form-group
          label="UID"
          label-cols-md="2"
          label-align="right"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="platform.uid"
            type="text"
            placeholder="Unique ID inside platform"
          />
        </b-form-group>
      </div>
      <div class="d-flex justify-content-end">
        <b-button
          type="submit"
          variant="primary"
        >
          {{ buttonText }}
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import {validateForm} from '@/utils/validation'
import { mapGetters } from 'vuex'
import VueUploadMultipleImage from 'vue-upload-multiple-image'

export default {
  components: {
    VueUploadMultipleImage
  },
  props: {
    modalTitle: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    modalId: {
      type: String,
      default: ''
    },
    modalData: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      form: {
        uid: '',
        title: '',
        categoryId: '',
        departmentId: ''
      },
      platforms: [],
      images: [],
      sendImage: null
    }
  },
  computed: {
    ...mapGetters({
      categories: 'categories/getCategories',
      getPlatforms: 'platforms/getPlatforms',
      departments: 'departments/getDepartments',
    }),
    departmentsList () {
      return [
        { id: '', title: 'Choose a department' },
        ...this.departments
      ]
    },
    categoriesList () {
      return [
        { id: '', title: 'Choose a category' },
        ...this.categories
      ]
    },
    platformsList () {
      return [
        { id: '', title: 'Choose a platform' },
        ...this.getPlatforms
      ]
    }
  },
  methods: {
    setData () {
      if (this.modalData) {
        this.form.title = this.modalData.title
        this.form.uid = this.modalData.uid
        this.form.categoryId = this.modalData.product_category_id
        this.form.departmentId = this.modalData.department_id
        this.modalData.platforms.forEach((platform) => {
          this.platforms.push({
            id: platform.id,
            link: platform.pivot.link,
            uid: platform.pivot.uid
          })
        })
        this.sendImage = this.modalData.icon
      }
      if (this.modalData?.icon) {
        this.images.push({
          path: this.modalData.icon,
          default: 1,
          highlight: 1,
          caption: ''
        })
      }
    },
    onTitleChange(){
      if(!this.form.uid){
        this.form.uid = this.form.title.toLowerCase().replace(' ', '_');
      }
    },
    handleCloseModal () {
      this.form = {
        uid: '',
        title: '',
        categoryId: ''
      }
      this.platforms = []
      this.images = []
      this.sendImage = null
    },
    async handleSubmit (event) {
      event.preventDefault()
      let isSuccess = true
      if (validateForm(this.form)) {
        const formData = new FormData()
        formData.append('title', this.form.title)
        formData.append('uid', this.form.uid)
        formData.append('product_category_id', this.form.categoryId)
        formData.append('department_id', this.form.departmentId)
        this.platforms.forEach((platform, index) => {
          formData.append(`platforms[${index}][id]`, platform.id)
          formData.append(`platforms[${index}][link]`, platform.link)
          formData.append(`platforms[${index}][uid]`, platform.uid)
        })
        formData.append('icon', this.sendImage)
        if (!this.modalData) {
          await this.$store.dispatch('products/createProduct', formData)
            .then((response) => {
              if (response.code != 200) {
                isSuccess = false
              }
            })
        } else {
          formData.append('_method', 'PUT')
          await this.$store.dispatch('products/updateProduct', {
            id: this.modalData.id,
            formData
          })
            .then((response) => {
              if (response.code != 200) {
                isSuccess = false
              }
            })
        }
        if (isSuccess) {
          this.$emit('success')
        }
      }
    },
    addPlatforms () {
      this.platforms.unshift({
        id: '',
        link: '',
        uid: ''
      })
    },
    uploadImageSuccess(formData) {
      for (let value of formData.values()) {
        this.sendImage = value
      }
    },
    beforeRemove () {
      this.images = []
      this.sendImage = null
    },
    editImage (formData) {
      for (let value of formData.values()) {
        this.sendImage = value
      }
    },
    removePlatform (index) {
      this.platforms.splice(index, 1)
    }
  }
}
</script>

<style>
.image-container {
  width: 100% !important;
}
.product-form__platform {
  border-top: 1px solid #EFEFEF;
  padding-top: 1rem;
  margin-bottom: 1rem;
}
</style>